import React, { useState } from "react";
import './TableRow.css';

const OwnershipTableRow = ({ label, values, onChange, tooltip }) => {
  // Initialize state for the input mode of each row (shares or percentage) 
  // We store the mode for each row separately using an array or object.
  const [inputModes, setInputModes] = useState(values.map(() => "shares")); // Default all rows to "shares"

  // Handles input changes based on the current input mode (shares or percentage)
  const handleInputChange = (index, value) => {
    if (value === "") {
      // If the input is empty, reset both fields
      onChange(index, "vc_shares", null);
      onChange(index, "percentage_ownership", null);
      return;
    }

    const parsedValue = parseFloat(value);

    // Skip invalid input
    if (isNaN(parsedValue)) {
      return;
    }

    if (inputModes[index] === "shares") {
      // When in "shares" mode, store the value as vc_shares
      onChange(index, "vc_shares", parsedValue);
      onChange(index, "percentage_ownership", null);
    } else {
      // If in "percentage" mode, store the value as percentage_ownership
      onChange(index, "percentage_ownership", parsedValue);
      onChange(index, "vc_shares", null);
    }
  };

  // Handle the mode toggle (shares or percentage) for each row
  const handleModeChange = (index, e) => {
    const newMode = e.target.value;
    const updatedModes = [...inputModes];
    updatedModes[index] = newMode; // Update the mode for the specific row
    setInputModes(updatedModes); // Set the updated mode array
  };

  return (
    <tr className="table-row">
      <td className="tooltip-container">
        {label}
        {tooltip && <span className="tooltip-text">{tooltip}</span>}
      </td>
      {values.map((value, index) => (
        <td key={index}>
          <div style={{ display: "flex", alignItems: "center" }}>
            <select
              value={inputModes[index]} // Mode set per row
              onChange={(e) => handleModeChange(index, e)}  // Update the mode for the current row
              style={{ marginRight: "8px" }}
            >
              <option value="shares">Shares (M)</option>
              <option value="percentage">Ownership (%)</option>
            </select>
            <input
              type="number"
              value={inputModes[index] === "shares"
                ? (value.vc_shares !== null && value.vc_shares !== undefined ? value.vc_shares : "")
                : (value.percentage_ownership !== null && value.percentage_ownership !== undefined ? value.percentage_ownership : "")}
              onChange={(e) => handleInputChange(index, e.target.value)} // Handle the value change
              step="0.01"
              required
              style={{ width: "100%" }}
            />
          </div>
        </td>
      ))}
    </tr>
  );
};

export default OwnershipTableRow;
