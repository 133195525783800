import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import BlackScholesCalculator from './BlackScholesCalculator';
import MultipleSeriesCalculator from './MultipleSeriesCalculator';
import IssueReport from './IssueReport';
import Ellipses from './Ellipses';
import './App.css';

function App() {
    const [activeComponent, setActiveComponent] = useState('OptionCalculator');
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [riskFreeRate, setRiskFreeRate] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false); // Track if the site is fully loaded
    const [recaptchaToken, setRecaptchaToken] = useState(null); // Store the reCAPTCHA token
    const [isCaptchaVerified, setIsCaptchaVerified] = useState(false); // Track reCAPTCHA completion
    const [isCaptchaLoaded, setIsCaptchaLoaded] = useState(false); // Flag to track reCAPTCHA script loading

    const RECAPTCHA_SITE_KEY = process.env.REACT_APP_RECAPTCHA_SITE_KEY; // Make sure this is in your .env file

    useEffect(() => {
        const token = localStorage.getItem('token');
        if (token) {
            setIsAuthenticated(true);
        }
    }, []);

    useEffect(() => {
        // Dynamically load the reCAPTCHA script
        const script = document.createElement('script');
        script.src = `https://www.google.com/recaptcha/api.js?render=${RECAPTCHA_SITE_KEY}`;
        script.async = true;
        script.onload = () => {
            console.log("reCAPTCHA script loaded.");
            setIsCaptchaLoaded(true); // Set the script as loaded
        };
        script.onerror = () => {
            console.error("Failed to load reCAPTCHA script.");
        };
        document.body.appendChild(script);
    }, []);

    useEffect(() => {
        const fetchRiskFreeRate = async () => {
            const apiUrl =
                window.location.hostname === 'localhost' || window.location.hostname === '127.0.0.1'
                    ? process.env.REACT_APP_API_URL_LOCAL + '/api/risk-free-rate'
                    : 'https://' + window.location.hostname + '/api/risk-free-rate';

            const controller = new AbortController();
            const timeoutId = setTimeout(() => controller.abort(), 5000); // Timeout of 5 seconds

            try {
                const response = await fetch(apiUrl, {
                    method: 'GET',
                    headers: { 'Content-Type': 'application/json' },
                    signal: controller.signal,
                });

                clearTimeout(timeoutId);

                if (!response.ok) throw new Error('Network response was not ok ' + response.statusText);

                const result = await response.json();
                setRiskFreeRate(result.risk_free_rate);
                setIsLoaded(true); // Set loading state to true once data is fetched
            } catch (error) {
                console.error('Error fetching risk-free rate:', error);
                setRiskFreeRate(0.02); // Default rate if fetch fails
                setIsLoaded(true); // Set loading state to true even on error
            }
        };

        fetchRiskFreeRate();
    }, []);

    // Handle reCAPTCHA token
    const handleRecaptcha = async () => {
        if (isCaptchaLoaded) {
            try {
                // Make sure grecaptcha is available before executing
                const recaptchaValue = await window.grecaptcha.execute(RECAPTCHA_SITE_KEY, { action: 'submit' });
                setRecaptchaToken(recaptchaValue); // Store the token
                setIsCaptchaVerified(true); // Mark reCAPTCHA as verified
                console.log("reCAPTCHA token received: ", recaptchaValue);
            } catch (error) {
                console.error("reCAPTCHA token error:", error);
            }
        } else {
            console.error("reCAPTCHA script is not yet loaded.");
        }
    };

    const renderComponent = () => {
        if (riskFreeRate === null) {
            return <Ellipses />;
        }

        switch (activeComponent) {
            case 'OptionCalculator':
                return <BlackScholesCalculator riskFreeRate={riskFreeRate} />;
            case 'MultipleSeriesCalculator':
                return <MultipleSeriesCalculator riskFreeRate={riskFreeRate} />;
            case 'IssueReport':
                return <IssueReport />;
            default:
                return <BlackScholesCalculator riskFreeRate={riskFreeRate} />;
        }
    };

    return (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', paddingTop: '50px' }}>
            <Helmet>
                <meta name="google-site-verification" content="IQSZFULcs3hsajaVkwTERdhK3ZXPqLWZwGZdpf6scE8" />
                <title>Term Sheet Genie - Decision Tool for Venture Capitalists and Founders</title>
                <meta name="description" content="An enhanced term sheet valuation tool using an advanced option pricing model to help venture capitalists and founders assess the value of the various deal terms" />
                <meta name="keywords" content="venture capital, investment decisions, VC tools, financial analysis" />
                <meta property="og:title" content="TermSheetGenie - Pricing Model Tool for VCs" />
                <meta property="og:description" content="An enhanced term sheet valuation tool using an advanced option pricing model to help venture capitalists and founders assess the value of the various deal terms" />
                <meta property="og:url" content="https://www.termsheetgenie.com" />
            </Helmet>
            <nav>
                <ul>
                    <li
                        className={activeComponent === 'OptionCalculator' ? 'bold' : ''}
                        onClick={() => setActiveComponent('OptionCalculator')}
                    >
                        Option Calculator
                    </li>
                    <li
                        className={activeComponent === 'MultipleSeriesCalculator' ? 'bold' : ''}
                        onClick={() => setActiveComponent('MultipleSeriesCalculator')}
                    >
                        Series Calculator
                    </li>
                    <li
                        className={activeComponent === 'IssueReport' ? 'bold' : ''}
                        onClick={() => setActiveComponent('IssueReport')}
                    >
                        Report an Issue
                    </li>
                </ul>
            </nav>
            
            {/* Render the component only if CAPTCHA is completed */}
            {isCaptchaVerified ? (
                <div className="component-container">
                    {renderComponent()}
                </div>
            ) : (
                <div style={{ textAlign: 'center', marginTop: '20px' }}>
                    <p>Please complete the reCAPTCHA to access the site.</p>
                </div>
            )}

            {/* Render reCAPTCHA and Submit buttons only if CAPTCHA is not verified */}
            {!isCaptchaVerified && (
                <div style={{ marginTop: '20px' }}>
                    <button onClick={handleRecaptcha} style={{ margin: '10px' }}>Validate reCAPTCHA</button>
                </div>
            )}

            {isLoaded && (
                <footer
                    style={{
                        marginTop: '50px',
                        padding: '20px',
                        backgroundColor: '#f8f9fa',
                        textAlign: 'left',
                        fontSize: '12px',
                        color: '#6c757d',
                        maxWidth: '800px',
                        margin: '0 auto',
                        textAlign: 'justify',
                    }}
                >
                    <p>
                        All information provided on this website is intended solely as a tool for assisting venture capitalists (VCs) and founders with decision-making processes. The content, analysis, and any recommendations offered by this tool are for informational purposes only and should not be considered as financial, legal, or investment advice. The tool is designed to assist VCs and founders in evaluating their deals but does not make decisions on behalf of the user. Users are responsible for their own investment decisions and should consult with qualified professional advisors, including financial, legal, and tax experts, prior to making any investment decisions. The site does not take responsibility for the correctness or completeness of the information provided and is not liable for any losses, damages, or missed opportunities resulting from the use of this tool. By using this website, users acknowledge that the data input into the system may be stored in our database tables for processing purposes. However, no data input should be construed as an offer or solicitation to purchase, sell, or make any investment. The tool does not recommend or promote the purchase or sale of any specific investment, product, or service. The content, trademarks, logos, and other proprietary information on this site are the property of TermSheetGenie and are protected by applicable intellectual property laws. Unauthorized use of this content is prohibited. This site may contain links to third-party websites. We do not control, endorse, or assume any responsibility for the content or services provided by third-party websites. Accessing third-party sites is done at your own risk. If any provision of this disclaimer is deemed invalid or unenforceable, the remaining provisions will continue in full force and effect.
                    </p>
                </footer>
            )}
        </div>
    );
}

export default App;
