import React from "react";
import "./Ellipses.css";

const Ellipses = () => {
    return (
        <div className="ellipses-container">
            <span className="dot"></span>
            <span className="dot"></span>
            <span className="dot"></span>
        </div>
    );
};

export default Ellipses;
