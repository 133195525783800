import React, { useState } from 'react';
import './IssueReport.css'; // Import the CSS file

const IssueReport = () => {
    const [issue, setIssue] = useState('');
    const [result, setResult] = useState('');

    const handleChange = (e) => {
        setIssue(e.target.value);
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        // Ensure the issue is not empty
        if (!issue.trim()) {
            alert('Please enter an issue before submitting.');
            return;
        }

        const data = {
            params: issue.trim() // Sending the issue as params
        };

        const apiUrl = window.location.hostname === 'localhost' || window.location.hostname === '127.0.0.1'
            ? process.env.REACT_APP_API_URL_LOCAL + '/api/report-issue'
            : 'https://' + window.location.hostname + '/api/report-issue';

        fetch(apiUrl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        })
        .then(response => {
            if (!response.ok) {
                throw new Error('Network response was not ok ' + response.statusText);
            }
            return response.json();
        })
        .then(result => {
            setResult('Issue reported successfully.');
            setIssue(''); // Clear the input field
        })
        .catch(error => {
            console.error('Error:', error);
            setResult('Error: ' + error.message);
        });
    };

    return (
        <div className="issue-report-container">
            <h2>Report an Issue</h2>
            <form onSubmit={handleSubmit}>
                <textarea
                    rows="4"
                    cols="50"
                    value={issue}
                    onChange={handleChange}
                    placeholder="Describe the issue..."
                    required
                />
                <br />
                <button type="submit">Submit Issue</button>
            </form>
            <div id="result">{result}</div>
        </div>
    );
};

export default IssueReport;
