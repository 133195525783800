import React from "react";
import './TableRow.css';

const TableRow = ({ label, values, onChange, options, type = "text", step = "1", disabled, tooltip }) => {
  return (
    <tr className="table-row">
      <td className="tooltip-container">
        {label}
        {tooltip && <span className="tooltip-text">{tooltip}</span>} {/* Tooltip text */}
      </td>
      {values.map((value, index) => (
        <td key={index}>
          {options ? (
            <select
              value={value}
              onChange={(e) => onChange(index, e.target.value)}
              disabled={disabled && disabled[index]}
              style={{ width: "100%" }} // Ensure dropdown fills column width
            >
              {options.map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </select>
          ) : (
            <input
              type={type}
              value={value}
              onChange={(e) => onChange(index, e.target.value)}
              step={step}
              required
              disabled={disabled && disabled[index]} // Disable based on the passed array
              style={{ width: "100%" }} // Ensure input fills column width
            />
          )}
        </td>
      ))}
    </tr>
  );
};

export default TableRow;
