import React from 'react';
import './ToggleSwitch.css';

const ToggleSwitch = ({ options, selected, onChange, name }) => {
    return (
        <div className="toggle-switch">
            {options.map((option) => (
                <React.Fragment key={option}>
                    <input
                        type="radio"
                        id={`${name}-${option}`}
                        name={name} // Unique name for the radio group
                        value={option}
                        checked={selected === option}
                        onChange={(e) => onChange(e.target.value)}
                    />
                    <label htmlFor={`${name}-${option}`}>{option}</label>
                </React.Fragment>
            ))}
        </div>
    );
};

export default ToggleSwitch;
