import React, { useState } from "react";
import TableRow from './TableRow'; // Adjust the path as necessary
import OwnershipTableRow from './OwnershipTableRow';
import Result from './ResultMultiple';
import ToggleSwitch from './ToggleSwitch';
import Ellipses from './Ellipses';
import './MultipleSeriesCalculator.css'; // Import the CSS file

const MultipleSeriesCalculator = ({ riskFreeRate }) => {
  const initialSeries = ["A"];
  const initialFormData = initialSeries.map((s, index) => ({
    id: index,
    investment_type: "RP+C",
    seniority: 1,
    investment_amount: 0,
    vc_shares: 0,
    percentage_ownership: 0,
    qpo_per_share: 1,
    cap_multiple: 1,
    volatility: 90,
    risk_free_rate: 0,
    holding_period: 0,
    committed_capital: 0,
    moic: 2.5,
    mgmt_fee: 2,
    performance_fee: 20,
    fund_lifetime: 10,
    time_volatility: 0,
    liquidation_preference_multiple: 1,
    annual_dividend_rate: 0,
    dividend_type: "None",
  }));

  const initialGlobalParams = {
    founder_shares: 0,
    subjective_current_value: 0,
    volatility: 90,
    risk_free_rate: riskFreeRate || 0,
    holding_period: 0,
    time_volatility: 0
  };

  const [series, setSeries] = useState(initialSeries);
  const [formData, setFormData] = useState(initialFormData);
  const [globalParams, setGlobalParams] = useState(initialGlobalParams);
  const [result, setResult] = useState(null);
  const [loading, setLoading] = useState(false);
  const [submittedData, setSubmittedData] = useState(null);
  const [isFixedExit, setIsFixedExit] = useState('No');
  const [mode, setMode] = useState('Yes');
  const [resultStatus, setResultStatus] = useState('');

  const updateFields = (id, field, value) => {
    setFormData((prevData) =>
      prevData.map((item) =>
        item.id === id ? { ...item, [field]: value } : item
      )
    );
  };

  const handleGlobalChange = (field, value) => {
    setGlobalParams((prevData) => ({
      ...prevData,
      [field]: value,
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true); // Set loading to true when the API call starts

    // Validation check for founder_shares and subjective_current_value
    if (Number(globalParams.founder_shares) <= 0) {
      setResult({ error: "Founder shares must be greater than 0" });
      setLoading(false); // Stop loading on error
      return; // Stop form submission if validation fails
    }

    if (Number(globalParams.subjective_current_value) <= 0) {
      setResult({ error: "Subjective company value must be greater than 0" });
      setLoading(false); // Stop loading on error
      return; // Stop form submission if validation fails
    }

    if (Number(globalParams.volatility) < 0) {
      setResult({ error: "Volatility must be greater than or equal to 0" });
      setLoading(false); // Stop loading on error
      return; // Stop form submission if validation fails
    }

    if (Number(globalParams.risk_free_rate) < 0) {
      setResult({ error: "Risk free rate must be greater than 0" });
      setLoading(false); // Stop loading on error
      return; // Stop form submission if validation fails
    }

    if (Number(globalParams.holding_period) < 0) { 
      setResult({ error: "Holding Period must be greater than or equal to 0" });
      setLoading(false); // Stop loading on error
      return; // Stop form submission if validation fails
    }

    if (mapToggleToMode(mode) === 'Gamma' && Number(globalParams.time_volatility) < 0) {
      setResult({ error: "Time volatility must be greater than or equal to 0" });
      setLoading(false); // Stop loading on error
      return; // Stop form submission if validation fails
    }

    const nonCommonSeries = formData.filter((data) => data.investment_type !== "Common");

    // Check if at least one non-common series has seniority of 1
    const hasValidSeniority = nonCommonSeries.some((data) => Number(data.seniority) === 1);
    if (!hasValidSeniority) {
      setResult({ error: "At least one non-common series must have a seniority of 1." });
      setLoading(false); // Stop loading on error
      return; // Stop form submission if validation fails
    }

    const minSeniority = 1;

    // Extract seniority values and sort them
    const seniorityValues = nonCommonSeries
      .map((data) => Number(data.seniority))
      .sort((a, b) => a - b);

    // Remove duplicates to focus only on unique seniority levels
    const uniqueSeniorities = [...new Set(seniorityValues)];

    // Check if the unique levels form a continuous sequence
    for (let i = 0; i < uniqueSeniorities.length; i++) {
      if (uniqueSeniorities[i] !== i + minSeniority) {
        setResult({ error: `Gaps detected in seniorities. Ensure seniorities form a continuous sequence` })
        setLoading(false);
        return;
      }
    }

    let totalPercentageOwnership = 0;
    let totalSharesKnown = Number(globalParams.founder_shares);

    // Ensure investment dates are in increasing order
    for (let i = 0; i < series.length; i++) {
      const currentDate = formData[i].investment_date ? new Date(formData[i].investment_date) : null;

      if (!currentDate) {
        setResult({ error: `Investment date for Series ${convertIndexToCharacter(i)} is required and cannot be empty` });
        setLoading(false); // Stop loading on error
        return; // Stop form submission if validation fails
      }

      if (i > 0) {
        const previousDate = new Date(formData[i - 1].investment_date);
        if (currentDate <= previousDate) {
            setResult({ error: `Investment date for Series ${convertIndexToCharacter(i)} must be after Series ${convertIndexToCharacter(i-1)}` });
            setLoading(false); // Stop loading on error
            return; // Stop form submission if validation fails
        }
      }

      // add the percentage ownership or shares known
      if (formData[i].percentage_ownership) {
        totalPercentageOwnership += Number(formData[i].percentage_ownership);
      } else if (formData[i].vc_shares) {
        totalSharesKnown += Number(formData[i].vc_shares);
      }
    }

    // Step 1: Check if total percentage ownership exceeds or equals 100%
    if (totalPercentageOwnership >= 100) {
      setResult({ error: "Total percentage ownership cannot be greater than or equal to 100%" });
      setLoading(false);
      return; // Stop form submission if validation fails
    }

    // Step 2: Calculate total shares
    const totalShares = totalSharesKnown / (1 - totalPercentageOwnership / 100);

    // Step 3: Update vc_shares for series where only percentage_ownership is defined
    formData.forEach((data, index) => {
      if (data.percentage_ownership && !data.vc_shares) {
        const sharesFromPercentage = (totalShares * data.percentage_ownership) / 100;
        formData[index].vc_shares = sharesFromPercentage.toFixed(6);
      }
    });

    const data = {
      founder_shares: Number(globalParams.founder_shares),
      subjective_current_value: Number(globalParams.subjective_current_value),
      params: series.map((s, index) => {
        const {
          investment_date,
          seniority,
          investment_amount,
          vc_shares,
          moic,
          mgmt_fee,
          performance_fee,
          fund_lifetime,
          committed_capital,
          liquidation_preference_multiple
        } = formData[index];

        // Error Checks for other fields
        if (Number(investment_amount) <= 0) {
          setResult({ error: `Series ${convertIndexToCharacter(index)} must have an investment amount greater than 0`});
          setLoading(false);
          return;
        }
        if (Number(vc_shares) <= 0) {
          setResult({ error: `Series ${convertIndexToCharacter(index)} must have VC shares greater than 0`});
          setLoading(false);
          return;
        }
        if (Number(committed_capital) <= 0) {
          setResult({ error: `Series ${convertIndexToCharacter(index)} must have committed capital greater than 0`});
          setLoading(false);
          return;
        }
        if (Number(moic) <= 0) {
          setResult({ error: `Series ${convertIndexToCharacter(index)} must have MOIC greater than 0` });
          setLoading(false);
          return;
        }
        if (Number(mgmt_fee) < 0) {
          setResult({ error: `Series ${convertIndexToCharacter(index)} must have non-negative management fee` });
          setLoading(false);
          return;
        }
        if (Number(performance_fee) < 0) {
          setResult({ error: `Series ${convertIndexToCharacter(index)} must have non-negative performance fee` });
          setLoading(false);
          return;
        }
        if (Number(fund_lifetime) <= 0) {
          setResult({ error: `Series ${convertIndexToCharacter(index)} must a fund lifetime greater than 0` });
          setLoading(false);
          return;
        }
        if (Number(liquidation_preference_multiple) <= 0) {
          setResult({ error: `Series ${convertIndexToCharacter(index)} must have liquidation preference multiple must be greater than zero. Use one if liquidation preferences are not mentioned in the termsheet`});
          setLoading(false);
          return;
        }

        const committedCapital = Number(committed_capital);
        const mgmtFee = Number(mgmt_fee) / 100;
        const performanceFee = Number(performance_fee) / 100;
        const fundLifetime = Number(fund_lifetime);
        const multipleOnInvestedCapital = Number(moic);

        const investedCapital = Number(calculateInvestedCapital(committedCapital, mgmtFee, fundLifetime));
        const gpPercentage = Number(calculateGPPercentage(index, committedCapital, investedCapital, multipleOnInvestedCapital, performanceFee));

        if (gpPercentage <= 0) {
          setResult({ error: `Series ${convertIndexToCharacter(index)} has a GP percentage that is less than or equal to 0. Please check your inputs for Expected MOIC, Annual Management Fee, and Fund Lifetime` });
          setLoading(false);
          return;
        }

        return {
          investment_date: investment_date,
          investment_type: mapInvestmentTypeToInput(formData[index].investment_type),
          seniority: Number(seniority),
          investment_amount: Number(investment_amount),
          vc_shares: Number(vc_shares),
          qpo_per_share: Number(formData[index].qpo_per_share),
          cap_multiple: Number(formData[index].cap_multiple),
          volatility: Number(globalParams.volatility) / 100,
          risk_free_rate: Number(globalParams.risk_free_rate) / 100,
          holding_period: Number(globalParams.holding_period),
          gp_percent: gpPercentage,
          committed_capital: Number(committed_capital),
          invested_capital: investedCapital,
          liquidation_preference_multiple: Number(formData[index].liquidation_preference_multiple),
          annual_dividend_rate: Number(formData[index].annual_dividend_rate),
          dividend_type: mapDividendTypeToInput(formData[index].dividend_type),
          committed_capital: committedCapital,
          moic: multipleOnInvestedCapital,
          annual_management_fee: mgmtFee,
          carry: performanceFee,
          fund_lifetime: fundLifetime,
          time_volatility: isFixedExit == 'Yes'
            ? 0
            : (mapToggleToMode(mode) === 'Gamma' ? Number(globalParams.time_volatility) : Number(globalParams.holding_period)),
        };
      }),
    };

    // Store the submitted data in state to pass to the Result component
    if (data && data.params && data.params[0]) {
      setSubmittedData(data);

      const apiUrl = window.location.hostname === 'localhost' || window.location.hostname === '127.0.0.1'
        ? process.env.REACT_APP_API_URL_LOCAL + '/api/multiple-series'
        : 'https://' + window.location.hostname + '/api/multiple-series';

      fetch(apiUrl, {
          method: 'POST',
          headers: {
              'Content-Type': 'application/json',
          },
          body: JSON.stringify(data),
      })
          .then(response => {
              if (!response.ok) throw new Error('Network response was not ok ' + response.statusText);
              return response.json();
          })
          .then(result => {
              setResult(result); // Set the result to the object returned from API
              setLoading(false); // Set loading to false after data is received
              setResultStatus('success');
          })
          .catch(error => {
              setResult({ error: `Error: ${error.message}` });
              setLoading(false); // Stop loading if there's an error
              setResultStatus('error');
          });
    }
  };



  const addSeries = () => {
    if (series.length < 10) { // Limit to 10 columns (A to J)
      const newIndex = String.fromCharCode(65 + series.length);
      setSeries([...series, newIndex]);
      setFormData([
        ...formData,
        {
          id: series.length,
          investment_type: "RP+C",
          seniority: 1,
          investment_amount: 0,
          vc_shares: 0,
          qpo_per_share: 1,
          cap_multiple: 1,
          moic: 2.5,
          mgmt_fee: 2,
          performance_fee: 20,
          fund_lifetime: 10,
          committed_capital: 0,
          liquidation_preference_multiple: 1,
          annual_dividend_rate: 0,
          dividend_type: "None",
        },
      ]);
    }
  };

  const removeSeries = () => {
    if (series.length > 1) { // Ensure Series A remains
      setSeries(series.slice(0, -1));
      setFormData(formData.slice(0, -1));
    }
  };

  const handleReset = () => {
    setSeries(initialSeries);
    setFormData(initialFormData);
    setGlobalParams(initialGlobalParams);
    setResult(null);
    setLoading(false);
    setSubmittedData(null);
    setResultStatus('');
  };

  const mapInvestmentTypeToInput = (investmentType) => {
    const investmentFormatted =  investmentType.toLowerCase().replace(/ /g, '_');
    if (investmentFormatted === 'rp+c') {
      return 'rp_common';
    }
    return investmentFormatted;
  };

  const mapDividendTypeToInput = (dividendType) => {
    return dividendType.toLowerCase().replace(/ /g, '_');
  };

  const calculateInvestedCapital = (committedCapital, mgmtFee, fundLifetime) => {
      return committedCapital * (1 - mgmtFee * fundLifetime);
  };

  const calculateGPPercentage = (index, committedCapital, investedCapital, moic, performanceFee) => {
      const temp = moic * investedCapital;
      return performanceFee * (temp - committedCapital)/temp;
  };

  const mapToggleToMode = (mode) => {
    if (mode === 'Yes') {
      return 'Gamma';
    }
    return 'Exponential';
  };

  const convertIndexToCharacter = (idx) => {
    return String.fromCharCode('A'.charCodeAt(0) + idx);
  }

  return (
    <div className="calculator-container">
      <h2>Series Calculator</h2>
      <h3>Global Parameters</h3>
      <h4>Are You Assuming a Fixed Exit?</h4>
      <ToggleSwitch
        name="fixed-exit"
        options={['Yes', 'No']}
        selected={isFixedExit}
        onChange={setIsFixedExit}
      />

      {isFixedExit === 'No' && (
        <div>
          <h4>Do You Know the Randomness Around the Holding Period?</h4>
          <ToggleSwitch
            name="randomness"
            options={['Yes', 'No']}
            selected={mode}
            onChange={setMode}
          />
        </div>
      )}

      <form onSubmit={handleSubmit}>
      <div className="tooltip-container">
          <label>
            Founder Shares (M):
            
              <input
                type="number"
                value={globalParams.founder_shares}
                onChange={(e) => handleGlobalChange("founder_shares", e.target.value)}
                step="0.01"
                required
              />
              <span className="tooltip-text">Number of shares held by the founder (in millions)</span>
            
          </label>
        </div>
        <br />
        <div className="tooltip-container">
          <label>
            Subjective Post Money Valuation ($M):
            
              <input
                type="number"
                value={globalParams.subjective_current_value}
                onChange={(e) =>
                  handleGlobalChange("subjective_current_value", e.target.value)
                }
                step="0.01"
                required
              />
              <span className="tooltip-text">The subjective current value of the company (in millions)</span>
            
          </label>
        </div>
        <br />
        <div className="tooltip-container">
          <label>
            Volatility (%):
            
              <input
                type="number"
                value={globalParams.volatility}
                onChange={(e) =>
                  handleGlobalChange("volatility", e.target.value)
                }
                step="0.01"
                required
              />
              <span className="tooltip-text">Expected volatility of the investment</span>
            
          </label>
        </div>
        <br />
        <div className="tooltip-container">
          <label>
            Risk Free Rate (%):
            
              <input
                type="number"
                value={globalParams.risk_free_rate}
                onChange={(e) =>
                  handleGlobalChange("risk_free_rate", e.target.value)
                }
                step="0.01"
                required
              />
              <span className="tooltip-text">The rate of return on a risk-free asset. The default value is the current 10 year Treasury Yield</span>
          </label>
        </div>
        <br />
        <div className="tooltip-container">
          <label>
            {isFixedExit === 'No' ? 'Expected Time to Exit (Yrs):' : 'Time to Exit (Yrs):'}
              <input
                type="number"
                value={globalParams.holding_period}
                onChange={(e) =>
                  handleGlobalChange("holding_period", e.target.value)
                }
                step="0.01"
                required
              />
              <span className="tooltip-text">The duration for which the the investor expects to hold the investment</span>
          </label>
        </div>
        <br />
        {isFixedExit === 'No' && (
          <div className="tooltip-container">
            <label>
              Volatility Around Holding Period (Yrs):
              <input
                type="number"
                value={globalParams.time_volatility}
                onChange={(e) =>
                  handleGlobalChange("time_volatility", e.target.value)
                }
                step="0.01"
                required
                disabled={mapToggleToMode(mode) !== 'Gamma'}
              />
              <span className="tooltip-text">
                Randomness around the holding period
              </span>
            </label>
          </div>
        )}

        <h3>Series-Specific Parameters</h3>
        <div className="button-container">          
          <button type="button" onClick={removeSeries} disabled={series.length <= 1}>Remove Series</button>
          <button type="button" onClick={addSeries} disabled={series.length >= 10}>Add Series</button>
        </div>
        <div className="table-scroll"> {/* Add this div for scrolling */}
          <fieldset>
            <legend>Deal Details</legend>
            <table>
              <thead>
                <tr>
                  <th style={{ minWidth: "150px" }}></th>
                  {series.map((s) => (
                    <th key={s} style={{ minWidth: "100px", textAlign: "center" }}>
                      Series {s}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {/* Date of Investment Row */}
                <TableRow
                  label="Date of Investment"
                  values={formData.map((data, index) => {
                    // Determine if this is the most recent series
                    const isLatestSeries = index === formData.length - 1;

                    // If it's the latest series and there's no date, default to today's date
                    if (isLatestSeries && !data.investment_date) {
                      const today = new Date().toISOString().split("T")[0];
                      updateFields(index, "investment_date", today); // Persist the default value
                      return today;
                    }

                    // If it's not the latest series and the value is empty, reset to a default
                    if (!isLatestSeries && !data.investment_date) {
                      const defaultValue = ""; // Or another meaningful default value
                      updateFields(index, "investment_date", defaultValue); // Persist the reset value
                      return defaultValue;
                    }

                    // Otherwise, return the current value
                    return data.investment_date;
                  })}
                  onChange={(index, value) => updateFields(index, "investment_date", value)}
                  type="date"
                  tooltip={["Date that the Series made the investment"]}
                />
                {/* Investment Type Row */}
                <TableRow
                  label="Investment Type"
                  values={formData.map(data => data.investment_type)}
                  onChange={(index, value) =>
                    updateFields(index, "investment_type", value)
                  }
                  options={["Common", "RP+C", "CP", "PCP", "PCPC"]}
                  tooltip={["The type of investment made by the investor"]}
                />

                {/* Seniority Row */}
                <TableRow
                  label="Seniority"
                  values={formData.map(data => data.seniority)}
                  onChange={(index, value) => {
                    const minSeniority = 1;
                    const maxSeniority = formData.filter((data) => data.investment_type !== "Common").length;
                    const newValue = Math.max(minSeniority, Math.min(value, maxSeniority));
                    updateFields(index, "seniority", newValue)
                  }}
                  type="number"
                  step="1"
                  disabled={formData.map(data => data.investment_type === "Common")}
                  tooltip={["The redemption order. The most senior series must start with a seniority of 1. Series with the same seniority redeem at the same time (pari passu)"]}
                />

                {/* Investment Amount Row */}
                <TableRow
                  label="Investment Amount ($M)"
                  values={formData.map(data => data.investment_amount)}
                  onChange={(index, value) =>
                    updateFields(index, "investment_amount", value)
                  }
                  tooltip={["The amount invested in the company (in millions)"]}
                />

                {/* Liquidation Preference Multiple Row */}
                <TableRow
                  label="Liquidation Preference Multiple"
                  values={formData.map(data => data.liquidation_preference_multiple)}
                  onChange={(index, value) =>
                    updateFields(index, "liquidation_preference_multiple", value)
                  }
                  disabled={formData.map(data => data.investment_type === "Common")}
                  tooltip={["Multiple applied during liquidation preference"]}
                  type="number"
                  step="0.01"
                />

                {/* Dividend Type Row */}
                <TableRow
                  label="Dividend Type"
                  values={formData.map(data => data.dividend_type)}
                  onChange={(index, value) =>
                    updateFields(index, "dividend_type", value)
                  }
                  options={["None", "Simple", "Compounded Annually", "Compounded Monthly", "Compounded Continuously"]}
                  disabled={formData.map(data => data.investment_type === "Common")}
                  tooltip={["The type of dividend distribution"]}
                />

                {/* Annual Dividend Rate Row */}
                <TableRow
                  label="Annual Dividend Rate (%)"
                  values={formData.map(data => 
                    data.dividend_type === "None" ? "" : (data.annual_dividend_rate * 100) || ""
                  )}
                  onChange={(index, value) =>
                    updateFields(index, "annual_dividend_rate", value ? value / 100 : 0)
                  }
                  disabled={formData.map(data => data.dividend_type === "None" || data.investment_type === "Common")} // Disable input when "dividend_type" is "None"
                  tooltip={["Annual dividend rate percentage"]}
                  type="number"
                  step="0.01"
                />

                {/* VC Shares Row */}
                <OwnershipTableRow
                  label="VC Ownership"
                  values={formData}  // Pass the full object (each `data` should include both `vc_shares` and `percentage_ownership`)
                  onChange={(index, field, value) => {
                    updateFields(index, field, value); // Update the field in the form data
                  }}
                  tooltip="Ownership allocated to the investor. Please input the number of shares (in millions) or the percentage ownership."
                />

                {/* QPO Per Share Row */}
                <TableRow
                  label="QPO Share Price ($)"
                  values={formData.map(data => data.qpo_per_share)}
                  onChange={(index, value) =>
                    updateFields(index, "qpo_per_share", value)
                  }
                  disabled={formData.map((data) => !(data.investment_type === "PCP" || data.investment_type === "PCPC"))}
                  tooltip={["Share price for the Qualified Public Offering, upon which the conversion is forced"]}
                />

                {/* Cap Multiple Row */}
                <TableRow
                  label="Cap Multiple Per Share"
                  values={formData.map(data => data.cap_multiple)}
                  onChange={(index, value) =>
                    updateFields(index, "cap_multiple", value)
                  }
                  disabled={formData.map((data) => !(data.investment_type === "PCPC"))}
                  tooltip={["The cap multiple is the maximum amount, expressed as a multiple of the original purchase price, that the preferred shareholders can receive in total returns (including both their initial liquidation preference and any additional participation) before their participation with Common Stock is capped."]}
                />
               </tbody>
              </table>
             </fieldset>

             <fieldset>
               <legend>Fund Details</legend>
               <table>
                <thead>
                  <tr>
                    <th style={{ minWidth: "150px" }}></th>
                    {series.map((s) => (
                      <th key={s} style={{ minWidth: "100px", textAlign: "center" }}>
                        Series {s}
                      </th>
                    ))}
                  </tr>
                </thead>
              <tbody>
                {/* Committed Capital Row */}
                <TableRow
                  label="Committed Capital ($M)"
                  values={formData.map(data => data.committed_capital)}
                  onChange={(index, value) =>
                    updateFields(index, "committed_capital", value)
                  }
                  tooltip={["Total capital committed to the investment fund (in millions)"]}
                />

                {/* MOIC Row */}
                <TableRow
                  label="Expected Multiple on Invested Capital"
                  values={formData.map(data => data.moic)}
                  onChange={(index, value) =>
                    updateFields(index, "moic", value)
                  }
                  tooltip={["MOIC expresses as a multiple how much a fund has made on the realization of a gain, relative to how much the fund paid for it. The default value is 2.5"]}
                />

                {/* Annual Management Fee Row */}
                <TableRow
                  label="Annual Management Fee (%)"
                  values={formData.map(data => data.mgmt_fee)}
                  onChange={(index, value) =>
                    updateFields(index, "mgmt_fee", value)
                  }
                  tooltip={["The management fee charged per year as a percentage of committed capital. The default value is 2%"]}
                />

                {/* Performance Fee Row */}
                <TableRow
                  label="Carry (%)"
                  values={formData.map(data => data.performance_fee)}
                  onChange={(index, value) =>
                    updateFields(index, "performance_fee", value)
                  }
                  tooltip={["The carry charged by the fund. The default value is 20%"]}
                />

                {/* Fund Lifetime Row */}
                <TableRow
                  label="Fund Lifetime (Yrs)"
                  values={formData.map(data => data.fund_lifetime)}
                  onChange={(index, value) =>
                    updateFields(index, "fund_lifetime", value)
                  }
                  tooltip={["Lifetime of the fund. The default value is 10 years"]}
                />
            </tbody>
          </table>
         </fieldset>
        </div>

        <div className="button-container">
            <button type="submit">Calculate</button>
            <button type="button" onClick={handleReset}>Reset</button>
        </div>
      </form>

      <div id="result" className={resultStatus === 'error' ? 'error' : 'success'}>
          {loading ? (
              <Ellipses /> // Loading indicator
          ) : result ? (
              result.error ? (
                  <div>
                    <span style={{ color: '#d8545f' }}>Error: {result.error}</span>
                  </div>
              ) : (
                  <Result data={result} submittedData={submittedData} />
              )
          ) : (
              <div></div>
          )}
      </div>
    </div>
  );
};

export default MultipleSeriesCalculator;
