import React, { useRef } from 'react';
import Plot from 'react-plotly.js';
import Plotly from 'plotly.js/dist/plotly';
import html2canvas from 'html2canvas';

const InvestmentGraph = ({ data }) => {
    const plotRef = useRef(null); // Create a reference for the Plot component

    const interpolate = (x, xValues, yValues) => {
        for (let i = 0; i < xValues.length - 1; i++) {
            if (x >= xValues[i] && x <= xValues[i + 1]) {
                const x0 = xValues[i];
                const y0 = yValues[i];
                const x1 = xValues[i + 1];
                const y1 = yValues[i + 1];
                const y = y0 + ((y1 - y0) / (x1 - x0)) * (x - x0);
                const effectiveOwnership = ((y / x) * 100).toFixed(2); // Calculate Effective Ownership as a percentage
                return { x, y: parseFloat(y.toFixed(2)), effectiveOwnership: parseFloat(effectiveOwnership) };
            }
        }
        return null; // Return null if x is out of range
    };

    const generateInterpolatedPoints = (xValues, yValues, existingPoints) => {
        const maxX = Math.max(...xValues);
        const minX = Math.min(...xValues); // Get the minimum existing x-value
        const interpolatedPoints = { x: [], y: [], text: [] };

        for (let x = minX; x <= maxX; x += 5) {
            if (x == 0) {
                continue;
            }
            // Interpolate even if the point exists, but with conditional text
            const interpolated = interpolate(x, xValues, yValues);
            if (interpolated) {
                interpolatedPoints.x.push(interpolated.x);
                interpolatedPoints.y.push(interpolated.y);
                // If x already exists, provide an empty string for text
                const existingIndex = existingPoints.indexOf(interpolated.x);
                const newText = existingIndex !== -1 
                    ? ''  // No new text if the point exists
                    : `(${interpolated.x}, ${interpolated.y})<br>Effective Ownership: ${interpolated.effectiveOwnership}%`;
                interpolatedPoints.text.push(newText);
            }
        }
        return interpolatedPoints;
    };

    // Extract original and interpolated data for investors
    const investorsData = data.investment_structures.map((structure, index) => {
        const existingPoints = structure.x_investor; // Existing x-values for this structure
        const interpolatedPoints = generateInterpolatedPoints(structure.x_investor, structure.y_investor, existingPoints);

        const color = `rgba(${(index * 50) % 255}, ${(index * 100) % 255}, ${(index * 150) % 255}, 1)`; // Get color for existing points
        return [
            {
                x: structure.x_investor,
                y: structure.y_investor,
                type: 'scatter',
                mode: 'lines+markers',
                name: structure.name,
                text: structure.x_investor.map((x, i) => 
                  `(${parseFloat(x.toFixed(2))}, ${parseFloat(structure.y_investor[i].toFixed(2))})<br>Effective Ownership: ${
                    x === 0 ? '0.00' : ((structure.y_investor[i] / x) * 100).toFixed(2)
                  }%`
                ),
                hoverinfo: 'text',
                marker: { size: 8, color },
                line: { color },
            },
            {
                x: interpolatedPoints.x,
                y: interpolatedPoints.y,
                type: 'scatter',
                mode: 'none',
                name: `${structure.name} (Interpolated)`,
                text: interpolatedPoints.text,
                hoverinfo: 'text',
                showlegend: false, // Hide legend for interpolated lines
                line: { color: color.replace(/, 1\)$/, ', 0.5)') }, // Semi-transparent line
            }
        ];
    }).flat(); // Flatten the array of traces

    // Extract interpolated data for the founder
    const founderInterpolatedPoints = generateInterpolatedPoints(data.x_founder, data.y_founder, data.x_founder);
    const founderData = [
        {
            x: data.x_founder,
            y: data.y_founder,
            type: 'scatter',
            mode: 'lines+markers',
            name: 'Founder',
            text: data.x_founder.map((x, i) => 
              `(${parseFloat(x.toFixed(2))}, ${parseFloat(data.y_founder[i].toFixed(2))})<br>Effective Ownership: ${
                x === 0 ? '0.00' : ((data.y_founder[i] / x) * 100).toFixed(2)
              }%`
            ),
            hoverinfo: 'text',
            marker: { size: 8, color: 'red' },
            line: { color: 'red' },
            hoverlabel: { bgcolor: 'rgba(255, 0, 0, 0.3)' }
        },
        {
            x: founderInterpolatedPoints.x,
            y: founderInterpolatedPoints.y,
            type: 'scatter',
            mode: 'none',
            name: 'Founder (Interpolated)',
            text: founderInterpolatedPoints.text,
            hoverinfo: 'text',
            showlegend: false, // Hide legend for interpolated lines
            hoverlabel: { bgcolor: 'rgba(255, 0, 0, 0.3)' } // Semi-transparent line
        }
    ];

    // Combine investors' and founder's data
    const plotData = [...investorsData, ...founderData];

    const maxYValue = Math.max(...data.y_founder, ...data.investment_structures.flatMap((structure) => structure.y_investor));

    const sortedPlotData = plotData.sort((a, b) => {
        if (a.name.toLowerCase() === 'founder') return 1;
        if (b.name.toLowerCase() === 'founder') return -1;
        return a.name.localeCompare(b.name);
    });
    
    const downloadGraph = () => {
        // Filter out the interpolated traces
        const filteredData = sortedPlotData.filter(trace => !trace.name.includes('(Interpolated)'));

        // Prepare the download data for the graph
        const downloadData = filteredData.map((trace) => ({
            ...trace,
            mode: 'lines+markers',
        }));

        const layout = {
            xaxis: {
                title: { text: 'Company Exit Value (MM)', font: { size: 14 } },
                showgrid: true,
                zeroline: true,
                title_standoff: 10,
                range: [0, Math.max(...data.x_founder) + 10],
            },
            yaxis: {
                title: { text: 'Value to All Shareholders (MM)', font: { size: 14 } },
                showline: true,
                title_standoff: 10,
                range: [0, maxYValue + 10],
            },
            hovermode: 'closest',
            margin: { l: 50, r: 50, t: 50, b: 100 },
            font: { size: 10 },
        };

        // Generate table headers dynamically based on the maximum number of inflection points
        const maxInflections = Math.max(...filteredData.map(trace => trace.x.length));
        const tableHeaders = Array.from({ length: maxInflections }, (_, i) => 
            `<th style="padding: 8px; background-color: #f2f2f2; font-weight: bold; border: 1px solid #ddd; text-align: center;">
                Inflection ${i + 1} (X, Y)<br>Effective Ownership
            </th>`
        ).join('');

        // Generate table rows for each series
        const tableRows = filteredData.map((trace) => {
            const seriesName = trace.name || 'Series';
            const inflectionPoints = trace.x.map((xVal, i) => {
                const yVal = trace.y[i];
                const text = trace.text[i] || ''; // Extract text field
                const ownershipMatch = text.match(/Effective Ownership: ([0-9.]+)%/); // Parse ownership from text
                const ownership = ownershipMatch ? `${parseFloat(ownershipMatch[1]).toFixed(2)}%` : 'N/A';
                return `(${xVal.toFixed(2)}, ${yVal.toFixed(2)})<br>${ownership}`;
            });

            // Pad the inflectionPoints array if it's shorter than maxInflections
            while (inflectionPoints.length < maxInflections) {
                inflectionPoints.push('N/A');
            }

            return `
                <tr>
                    <td style="padding: 8px; background-color: #f9f9f9; border: 1px solid #ddd; text-align: center; font-weight: bold;">${seriesName}</td>
                    ${inflectionPoints.map(point => `<td style="padding: 8px; border: 1px solid #ddd; text-align: center;">${point}</td>`).join('')}
                </tr>
            `;
        });

        // Table HTML
        const tableHTML = `
            <table border="1" style="width:800px; text-align:center; font-size:12px; margin-top:20px; table-layout:fixed;">
                <thead>
                    <tr>
                        <th style="padding: 8px; background-color: #f2f2f2; font-weight: bold; border: 1px solid #ddd; text-align: center;">Series</th>
                        ${tableHeaders}
                    </tr>
                </thead>
                <tbody>
                    ${tableRows.join('')}
                </tbody>
            </table>
        `;

        // Create an offscreen container
        const containerDiv = document.createElement('div');
        containerDiv.style.position = 'absolute';
        containerDiv.style.top = '-9999px';
        containerDiv.style.left = '-9999px';
        document.body.appendChild(containerDiv);

        // Create a graph div
        const graphDiv = document.createElement('div');
        graphDiv.style.width = '800px';
        graphDiv.style.height = '600px';
        containerDiv.appendChild(graphDiv);

        // Add table below the graph
        const tableDiv = document.createElement('div');
        tableDiv.innerHTML = tableHTML;
        tableDiv.style.marginTop = '20px';
        containerDiv.appendChild(tableDiv);

        // Plot the graph
        Plotly.newPlot(graphDiv, downloadData, layout).then(() => {
            html2canvas(containerDiv, { scale: 2 }).then(canvas => {
                const imageData = canvas.toDataURL('image/png');
                const link = document.createElement('a');
                link.href = imageData;
                link.download = 'investment-graph.png';
                link.click();

                // Clean up
                document.body.removeChild(containerDiv);
            });
        });
    };


    const isMobile = window.innerWidth <= 600;

    return (
        <div className="chart-container" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            {!isMobile && (
                <Plot
                    ref={plotRef}
                    data={plotData}
                    layout={{
                        xaxis: { title: { text: 'Company Exit Value (MM)', font: { size: 14 } }, showgrid: true, zeroline: true, title_standoff: 10 },
                        yaxis: { title: { text: 'Value to All Shareholders (MM)', font: { size: 14 } }, showline: true, title_standoff: 10 },
                        hovermode: 'closest',
                        margin: { l: 50, r: 50, t: 10, b: 50 },
                        legend: { orientation: 'h', xanchor: 'center', y: -0.2, x: 0.5 },
                    }}
                    config={{ responsive: true, displayModeBar: false }}
                    style={{ height: '400px', width: '100%' }}
                />
            )}
            <button onClick={downloadGraph} className="download-btn" style={{ marginTop: '10px' }}>
                Download Graph
            </button>
        </div>
    );
};

export default InvestmentGraph;
